import { navigate, redirectTo, useLocation, useMatch } from "@reach/router";
import { useMemo, useContext } from "react";
import { PathContext } from "./router.context";
import q from "query-string";

export interface Navigator {
  go: (path: string) => Promise<void>;
  back: () => void;
  redirect: (path: string, options?: { external: boolean }) => void;
  replace: (path: string) => Promise<void>;
}

export const useMatchPath = (
  path: string
): null | {
  uri: string;
  path: string;
  [param: string]: string;
} => {
  const match = useMatch(path);
  return match;
};

export const usePathHelpers = (): [(path: string) => string] => {
  const context = useContext(PathContext);
  const toPath = (path: string): string => {
    if (path.charAt(0) == "/") {
      return `${context.basePath}${path}`;
    }
    return `${context.basePath}/${path}`;
  };
  return [toPath];
};

export const useNavigator = (): Navigator => {
  const [toPath] = usePathHelpers();
  const navigator = useMemo(() => {
    const go = (path: string) => navigate(toPath(path));
    const back = () => navigate(-1);
    const redirect = (path: string, options?: { external: boolean }): void => {
      let external = false;
      if (options) {
        external = options.external || false;
      }
      const url = external ? path : toPath(path);
      redirectTo(url);
    };

    const replace = async (path: string): Promise<void> => {
      navigate(toPath(path), { replace: true });
    };

    return {
      go,
      back,
      redirect,
      replace,
    };
  }, []);

  return navigator;
};

export interface QueryParams {
  getString: (name: string) => string | undefined;
}

export const useQueryParams = (): QueryParams => {
  const location = useLocation();
  const queryParams = q.parse(location.search);
  const getString = (name: string): string | undefined => {
    if (queryParams) {
      const value = queryParams[name];
      if (typeof value === "string") {
        return value;
      }
    }
    return undefined;
  };
  return {
    getString,
  };
};
