import React, { PropsWithChildren, useMemo } from "react";
import { Link as LinkComp } from "@reach/router";

export const LinkExternal = (
  props: PropsWithChildren<{
    to: string;
    underline?: boolean;
    onHoverUnderline?: boolean;
    className?: string;
  }>
) => {
  const { underline = false, onHoverUnderline = false, className } = props;

  const classNames = useMemo(() => {
    const elems = [];
    if (underline) {
      elems.push("_r_underline _r_underline-offset-2");
    }
    if (onHoverUnderline) {
      elems.push(
        "_r_underline _r_underline-offset-2 _r_decoration-transparent hover:_r_decoration-current _r_transition _r_duration-300 _r_ease-in-out"
      );
    }
    if (className) {
      elems.push(className);
    }
    return elems.join(" ");
  }, [className, underline, onHoverUnderline]);

  return (
    <LinkComp className={classNames} to={props.to}>
      {props.children}
    </LinkComp>
  );
};
