import React, { PropsWithChildren, useMemo } from "react";
import {
  Redirect,
  WindowLocation,
  LocationProvider,
  Router as RouterComp,
} from "@reach/router";

import { PathContext, SecureContext } from "./router.context";
import { RouteProps, SecurityContext } from "./router.type";

const normalizeBasePath = (basePath: string): string => {
  if (!basePath || basePath === "") {
    return "";
  }
  // remove the slash character at the last pos and add it into the first pos if it's missing
  let path = basePath;
  if (path.charAt(path.length - 1) == "/") {
    path = path.substring(0, path.length - 1);
  }
  if (path.charAt(0) != "/") {
    path = "/" + path;
  }
  return path;
};

export const Router = (
  props: PropsWithChildren<{
    basePath?: string;
    primary?: boolean;
  }>
) => {
  const { basePath = "", primary } = props;

  const calculated_base_path = useMemo(() => {
    return normalizeBasePath(basePath);
  }, [basePath]);

  return (
    <PathContext.Provider value={{ basePath: calculated_base_path }}>
      <LocationProvider>
        {({ location }) => {
          const internal: WindowLocation = location;
          const { pathname } = internal;
          const { pathname: currentWindowPath } = window.location;
          if (pathname !== currentWindowPath) {
            // this usually should not happen
            internal.pathname = currentWindowPath;
          }
          return (
            <RouterComp
              basepath={calculated_base_path}
              location={internal}
              primary={primary}
            >
              {props.children}
            </RouterComp>
          );
        }}
      </LocationProvider>
    </PathContext.Provider>
  );
};

export const ProtectedRouter = (
  props: PropsWithChildren<{
    basePath?: string;
    primary?: boolean;
    securityContext: SecurityContext;
    loginFlowUrl: string;
  }>
) => {
  const { basePath = "", primary, securityContext, loginFlowUrl } = props;
  const calculated_base_path = useMemo(() => {
    return normalizeBasePath(basePath);
  }, [basePath]);

  return (
    <PathContext.Provider value={{ basePath: calculated_base_path }}>
      <SecureContext.Provider value={securityContext}>
        <LocationProvider>
          {({ location }) => {
            const internal: WindowLocation = location;
            const { pathname } = internal;
            const { pathname: currentWindowPath } = window.location;
            if (pathname !== currentWindowPath) {
              // this usually should not happen
              internal.pathname = currentWindowPath;
            }
            return (
              <SecureContext.Consumer>
                {({ isAuthenticated = null }) => {
                  return isAuthenticated && isAuthenticated() ? (
                    <RouterComp
                      basepath={calculated_base_path}
                      location={internal}
                      primary={primary}
                    >
                      {props.children}
                    </RouterComp>
                  ) : (
                    <Redirect to={loginFlowUrl} noThrow />
                  );
                }}
              </SecureContext.Consumer>
            );
          }}
        </LocationProvider>
      </SecureContext.Provider>
    </PathContext.Provider>
  );
};

export const GroupRoute = (props: PropsWithChildren<RouteProps>) => {
  return <>{props.children}</>;
};
