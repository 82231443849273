import React, { PropsWithChildren } from "react";
import { usePathHelpers } from "../router";
import { LinkExternal } from "./external_link.component";

export const LinkInternal = (
  props: PropsWithChildren<{
    to: string;
    underline?: boolean;
    onHoverUnderline?: boolean;
    className?: string;
  }>
) => {
  const { underline = false, onHoverUnderline = false, className } = props;
  const [p] = usePathHelpers();
  return (
    <LinkExternal
      to={p(props.to)}
      underline={underline}
      onHoverUnderline={onHoverUnderline}
      className={className}
    >
      {props.children}
    </LinkExternal>
  );
};
